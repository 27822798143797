.sidebar{
  width: 100%;
  height: 100vh;
  background: #FBFBF7;
  position: fixed;
  top: 0;
  left: 0;
  transition: .3s;

  .up{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    img{
      width: 100px;
    }
    li{
      list-style: none;
      font-size: 26px;
    }
  }

  .list{
    padding: 0 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 60px;
    li{
      list-style: none;
      a{
        text-decoration: none;
        font-size: 20px;
        color: #868488;
        &:hover{
          color: black;
        }
      }
    }
  }

  footer{
    display: flex;
    align-items: center;
    padding: 0 30px;
    margin-top: 120px;
    li{
      list-style: none;
      &.social{
        padding: 0;
        margin-right: 10px;
        a{
          padding: 6px;
          border: 1px solid #868488;
          border-radius: 6px;
        }
      }
      a{
        text-decoration: none;
        color: #868488;
        font-size: 18px;
      }
    }
    span{
      width: 2px;
      height: 50px;
      background: #868488;
      margin: 0 10px;
    }
  }
}

.sidebar_none{
  width: 100%;
  height: 100vh;
  background: #FBFBF7;
  position: fixed;
  top: 0;
  left: 100%;
  transition: .3s;

  .up{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    img{
      width: 100px;
    }
    li{
      list-style: none;
      font-size: 26px;
    }
  }

  .list{
    padding: 0 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 60px;
    li{
      list-style: none;
      a{
        text-decoration: none;
        font-size: 20px;
        color: #868488;
        &:hover{
          color: black;
        }
      }
    }
  }

  footer{
    display: flex;
    align-items: center;
    padding: 0 30px;
    margin-top: 120px;
    li{
      list-style: none;
      &.social{
        padding: 0;
        margin-right: 10px;
        a{
          padding: 6px;
          border: 1px solid #868488;
          border-radius: 6px;
        }
      }
      a{
        text-decoration: none;
        color: #868488;
        font-size: 18px;
      }
    }
    span{
      width: 2px;
      height: 50px;
      background: #868488;
      margin: 0 10px;
    }
  }
}