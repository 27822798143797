.lastCases{
  margin-top: 150px;
  padding: 0 60px;
	@media (max-width: 505px) {
		padding: 0 30px;
	}
  .cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .card{
      width: 420px;
      height: 500px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 12px;
      position: relative;
      @media (max-width: 1308px) {
        &:nth-child(4){
          display: none;
        }
      }
      @media (max-width: 942px) {
        &:nth-child(3){
          display: none;
        }
      }
      @media (max-width: 658px) {
        &:nth-child(2){
          display: none;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -100;
        border-radius: 12px;
      }
      .up{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 35px;
        p{
          margin: 0 ;
          font-size: 12px;
          color: white ;
        }
        div{
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          gap: 7px;
          p{
            width: fit-content;
            padding: 8px 10px;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
            margin: 0;
            font-size: 12px;
          }
        }
      }
  
      a{
        text-decoration: none;
        .down{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 35px;
          h2{
            font-size: 18px;
            color: white ;
            margin: 0;
            font-weight: 400;
          }
          li{
            list-style: none;
            color: white ;
          }
        }
      }
    }
  }

  .info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media (max-width: 1053px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h1{
      width: 610px;
      font-size: 36px;
      @media (max-width: 1316px) {
        width: 510px;
        font-size: 26px;
      }
      @media (max-width: 1053px) {
        width: 90%;
      }
    }

    h3{
      width: 404px;
      font-size: 16px;
      font-weight: 400;
      @media (max-width: 1053px) {
        width: 90%;
      }
    }

    button{
      width: 250px;
      height: 60px;
      border-radius: 50px;
      border: 1px solid #FD7632;
      background: none;
      color: #FD7632;
      transition: .3s;
      &:hover{
        background: #FD7632;
        color: white;
        cursor: pointer;
      }
    }
  }
}