.navbar{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FBFBF7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .logo{
    margin-left: 60px;
    @media (max-width: 505px) {
      margin-left: 30px;
    }
    img{
      width: 100px;
    }
  }

  .list{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 300px;
    @media (max-width: 1386px) {
      margin-left: 200px;
    }
    @media (max-width: 1286px) {
      margin-left: 50px;
    }
    @media (max-width: 826px) {
      margin-left: 0px;
    }
    @media (max-width: 610px) {
      display: none;
    }
    li{
      list-style: none;
      a{
        text-decoration: none;
        padding: 10px 26px;
        border: 1px solid #868488;
        border-radius: 50px;
        color: #868488;
        font-size: 15.5px;
        transition: .3s;
        &:hover{
          border: 1px solid black;
          color: black;
        }

        @media (max-width: 1286px) {
          font-size: 13.5px;
        }
      }
    }
  }

  .bar{
    display: none;
    margin-right: 60px;
    @media (max-width: 505px) {
      margin-right: 30px;
    }
    li{
      list-style: none;
      font-size: 20px;
    }
    @media (max-width: 1116px) {
      display: block;
    }
  }
  

  
  .nav_right{
    display: flex;
    align-items: center;
    gap: 13px;  
    @media (max-width: 1116px) {
      display: none;
    }
    margin-right: 60px;
    @media (max-width: 505px) {
      margin-right: 30px;
    }
    .right__nav{
      display: flex;
      align-items: center;
      gap: 30px;
      li{
        list-style: none;
        a{
          text-decoration: none ;
          color: #868488;
          transition: .3s;
          font-size: 12px;
          font-weight: 600;
          &:hover{
            color: black;
          }
        }
      }
    }
    span{
      width: 2px;
      height: 20px;
      background: #868488;
    }
    li{
      list-style: none;
      a{
        text-decoration: none;
        color: #868488;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        &.icon{
          font-size: 20px;
        }
      }
    }
  }
}