@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900');

@font-face {
  font-family: "Gilroy Medium";
  src: url("./fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy Regular";
  src: url("./fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "Gilroy Thin";
  src: url("./fonts/Gilroy-Thin.ttf");
}

@font-face {
  font-family: "Gilroy Bold";
  src: url("./fonts/Gilroy-SemiBold.ttf");
}




*{
	font-family: Gilroy Medium;
}

body{
	background: #FBFBF7;
	margin: 0;
}

.seo{
	display: none;
}