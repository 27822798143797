.cases{
  padding: 0 60px;
	@media (max-width: 505px) {
		padding: 0 30px;
	}
  margin-top: 50px;
  .cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .card{
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 12px;
      position: relative;
      @media (max-width: 1308px) {
        &:nth-child(4){
          display: none;
        }
      }
      @media (max-width: 942px) {
        &:nth-child(3){
          display: none;
        }
      }
      @media (max-width: 658px) {
        &:nth-child(2){
          display: none;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -100;
        border-radius: 12px;
      }
      .up{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 35px;
        p{
          margin: 0 ;
          font-size: 12px;
          color: white ;
        }
        div{
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          gap: 7px;
          p{
            width: fit-content;
            padding: 8px 10px;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
            margin: 0;
            font-size: 12px;
          }
        }
      }
  
      a{
        text-decoration: none;
        .down{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 35px;
          h2{
            font-size: 18px;
            color: white ;
            margin: 0;
            font-weight: 400;
            text-shadow: 0 0 10px black;
          }
          li{
            list-style: none;
            color: white ;
          }
        }
      }
    }
  }
}